export default [
  {
    header: 'Accounts',
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Invoice',
        route: 'invoices',
        resource: 'biz_invoices',
        action: 'view',
      },
    ],
  },
  {
    title: 'Wallet',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Deposit',
        route: 'deposit',
        resource: 'biz_wallet',
        action: 'view',
      },
      {
        title: 'Credit',
        route: 'credit',
        resource: 'biz_wallet',
        action: 'view',
      },
    ],
  },
]
