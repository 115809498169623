export default [
  {
    header: 'Reservation',
  },
  {
    title: 'Bookings',
    icon: 'ListIcon',
    route: 'booking-list',
    children: [
      {
        title: 'Flight Bookings',
        route: 'flight-bookings',
        resource: 'biz_bookings',
        action: 'view',
      },
      {
        title: 'Hotel Bookings',
        route: 'hotel-bookings',
        resource: 'biz_bookings',
        action: 'view',
      },
      {
        title: 'Bus Bookings',
        route: 'bus-bookings',
        resource: 'biz_bookings',
        action: 'view',
      },
      {
        title: 'Tour Bookings',
        route: 'tour-bookings',
        resource: 'biz_bookings',
        action: 'view',
      },
      {
        title: 'All Trips',
        route: 'all',
        resource: 'biz_all_trips',
        action: 'view',
      },
    ],
  },
]
