// import book from './share'
import reservation from './reservation'
import reports from './reports'
import users from './users'
import accounts from './accounts'
import others from './others'
import store from './store'

export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Search',
    route: 'search',
    icon: 'ArrowUpRightIcon',
  },
  ...reservation,
  ...users,
  ...accounts,
  ...reports,
  ...store,
  ...others,
]
