<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 200 189"
    fill="none"
  >
    <path
      d="M185.443 50.2177C181.255 41.1555 174.276 33.6806 165.533 28.8932C160.487 26.1281 154.967 24.3377 149.262 23.6151C142.837 22.801 136.313 23.3359 130.104 25.1857L129.479 25.3751C116.236 29.5417 103.955 36.3147 93.3544 45.2978L92.9123 45.47C90.7186 45.6084 88.508 45.6863 86.2779 45.6863C50.4335 45.6863 18.8217 27.5822 0 0C0.84082 38.5798 22.4571 72.0217 54.0747 89.5385C46.4582 101.375 40.1474 114.005 35.2531 127.209C35.0528 127.749 34.8506 128.287 34.656 128.829C27.7459 147.997 23.8845 168.135 23.2129 188.506C29.1935 168.609 40.9294 150.938 56.9337 137.733C57.8572 136.968 58.7943 136.218 59.7453 135.484C63.1846 135.88 66.6436 136.08 70.1056 136.081C91.8217 136.114 112.815 128.261 129.205 113.976L129.473 113.995L129.261 113.926C135.161 108.785 140.373 102.902 144.769 96.4213C146.195 94.3181 147.533 92.1535 148.784 89.927C150.237 87.3429 151.559 84.6809 152.752 81.9415C154.801 78.3235 156.619 74.5792 158.198 70.7318C163.742 65.3507 171.068 62.2049 178.778 61.8943C186.489 61.5837 194.043 64.1301 200 69.0476C197.794 61.1018 192.567 54.3414 185.443 50.2177Z"
      fill="url(#paint0_linear_501_3)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_501_3"
        x1="0"
        y1="94.2519"
        x2="200.008"
        y2="94.2519"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#01026E" />
        <stop
          offset="1"
          stop-color="#1D4DA2"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'GoZayaanBirdLogo',
}
</script>

<style lang="scss" scoped></style>
