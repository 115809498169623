export default [
  {
    header: 'Store',
  },
  {
    title: 'Sales',
    icon: 'DollarSignIcon',
    route: 'sales',
    children: [
      {
        title: 'List',
        route: 'sales-invoice-list',
        resource: 'sales_invoice_list',
        action: 'view',
      },
      {
        title: 'Create Invoice',
        route: 'sales-invoice-form',
        resource: 'sales_invoice_create',
        action: 'view',
      },
    ],
  },
]
